import styled from 'styled-components'
import { Link } from 'gatsby'
import media from '../../utils/media'

export const Nav = styled.nav`
  z-index: 5;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  ${media.lessThan('sm')`
    width: ${({ isHome }) => (isHome ? '50px' : '100%')};
  `};

  background-color: ${({ isHome }) => (isHome ? 'transparent' : 'white')};

  font-weight: 400;
  font-size: 20px;
  line-height: 50px;
  color: ${({ isHome }) => (isHome ? '#fff' : '#000')};

  transition: opacity 0.5s, visibility 0.5s;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export const Ul = styled.ul`
  width: 100%;
  height: 100%;
  max-width: 1940px;
  margin: 0 auto;

  background-color: inherit;
  color: inherit;

  transition: opacity 0.5s, visibility 0.5s;

  /* Position change is delayed using changePos variable */
  ${({ changePos }) =>
    !changePos
      ? `padding: 0 70px; position: static;`
      : `padding: 0; position: fixed;`};

  ${media.greaterThan('sm')` ${({ changePos }) =>
    !changePos
      ? `opacity: 1; visibility: visible;`
      : `opacity: 0; visibility: hidden;`};
  `};

  ${media.lessThan('sm')` ${({ visible, changePos }) =>
    visible && changePos
      ? `opacity: 1; visibility: visible;`
      : `opacity: 0; visibility: hidden;`};
  `};
`

export const Li = styled.li`
  margin: 0;
  color: inherit;
  text-align: center;
  user-select: none;

  ${({ changePos }) =>
    changePos
      ? `float:none;
        position:relative;
        top: calc(50% - 120px);
        font-size: 32px;
        line-height: 40px;`
      : `float: left;
        padding: 0 15px 0 20px;
        &:first-child { padding-left: 0; }
        // 2023-08-25: Nicole's request to hide the "order" page
        // &:last-child { float: right;}
  `};
`

export const NavLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &.active {
    opacity: 0.4;
  }

  &:hover {
    opacity: 0.6;
  }
`

export const Button = styled.button`
  position: fixed;
  width: 36px;
  height: 36px;
  margin: 7px;
  top: 0;
  left: 0;
  z-index: 4;

  background-color: transparent;
  border-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  transition: all 0.5s ease-in-out;
  transform: rotate(0deg);

  opacity: ${({ visible }) => (visible ? '1' : '0')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

export const Span = styled.span`
  display: block;
  position: fixed;

  height: 2px;
  width: 100%;
  left: 0;

  background-color: ${({ isHome }) => (isHome ? '#fff' : '#000')};

  transition: all 0.25s ease-in-out;
  transform: rotate(0deg);

  &:nth-child(1) {
    top: 0px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    top: 12px;
  }

  &:nth-child(4) {
    top: 24px;
  }

  &.open {
    &:nth-child(1) {
      top: 12px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 12px;
      width: 0%;
      left: 50%;
    }
  }
`
