import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'

import * as El from './styles'

import {
  getHeaderPagesData,
  transformQueryDataToObj
} from '../../constants/locales'

const smBreakpoint = 769

class Header extends Component {
  state = {
    menuBtnClicked: false,
    topNavVisible: true,
    timeoutHandler: false,
    changePos: false,
    pages: null
  }

  componentWillMount () {
    const { pageTitles, locale, location } = this.props
    const pages = getHeaderPagesData(pageTitles, locale)

    this.setState({
      isHome:
        location.pathname === pages[0].path ||
        location.pathname + '/' === pages[0].path,
      pages: pages
    })
  }

  componentDidMount () {
    window.addEventListener('resize', this.onWindowResize)
    this.setState({
      windowWidth: window.innerWidth,
      topNavVisible: window.innerWidth > smBreakpoint,
      changePos: window.innerWidth < smBreakpoint
    })
  }

  componentWillUnmount () {
    clearTimeout(this.state.timeoutHandler)
    window.removeEventListener('resize', this.onWindowResize)
  }

  changeNavbarPosition = (status) => {
    clearTimeout(this.state.timeoutHandler)

    // delay navbar position change, so fade transitions can finish
    this.state.timeoutHandler = setTimeout(() => {
      this.setState({ changePos: status })
    }, 500)
  }

  onWindowResize = () => {
    const oldWidth = this.state.windowWidth
    const newWidth = window.innerWidth
    const sm = smBreakpoint

    if (newWidth > oldWidth && oldWidth <= sm && newWidth > sm) {
      /* When window becomes bigger than sm breakpoint */
      this.setState({ topNavVisible: true }, () => {
        this.changeNavbarPosition(false)
        if (this.state.isHome) this.props.toggleBackgroundBlur(false)
      })
    }
    if (newWidth < oldWidth && oldWidth > sm && newWidth <= sm) {
      /* When window becomes smaller than sm breakpoint */
      this.setState(
        {
          menuBtnClicked: false,
          topNavVisible: false
        },
        () => {
          this.changeNavbarPosition(true)
        }
      )
    }

    this.state.windowWidth = window.innerWidth
  }

  toggleMenu = () => {
    this.setState({ menuBtnClicked: !this.state.menuBtnClicked })
    if (this.state.isHome) {
      this.props.toggleBackgroundBlur(!this.state.menuBtnClicked)
    }
  }

  menuButton = () => {
    const { isHome } = this.state
    const className = this.state.menuBtnClicked ? 'open' : ''

    return (
      <El.Button onClick={this.toggleMenu} visible={!this.state.topNavVisible}>
        <El.Span className={className} isHome={isHome} />
        <El.Span className={className} isHome={isHome} />
        <El.Span className={className} isHome={isHome} />
        <El.Span className={className} isHome={isHome} />
      </El.Button>
    )
  }

  navbar = () => {
    const { pages, changePos, topNavVisible, menuBtnClicked } = this.state
    const visible = (menuBtnClicked && !topNavVisible) || topNavVisible

    return (
      <El.Ul visible={visible} changePos={changePos}>
        {pages.map((page, i) => (
          <El.Li key={`main-nav__${i}`} visible={visible} changePos={changePos}>
            <El.NavLink to={page.path} activeClassName='active'>
              {page.name}
            </El.NavLink>
          </El.Li>
        ))}
      </El.Ul>
    )
  }

  render = () => (
    <header>
      <El.Nav isHome={this.state.isHome} visible={!this.props.overlayOpened}>
        {this.menuButton()}
        {this.navbar()}
      </El.Nav>
    </header>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulPageHome {
          edges {
            node {
              pageTitle
            }
          }
        }
        allContentfulPageEvent {
          edges {
            node {
              pageTitle
              inHeader
            }
          }
        }
        allContentfulPageSpecials {
          edges {
            node {
              pageTitle
              inHeader
            }
          }
        }
        allContentfulPageSubscriptions {
          edges {
            node {
              pageTitle
              inHeader
            }
          }
        }
        allContentfulPageInfo {
          edges {
            node {
              pageTitle
              inHeader
            }
          }
        }
        allContentfulPageContact {
          edges {
            node {
              pageTitle
              inHeader
            }
          }
        }
        # 2023-08-25: Nicole's request to hide the "order" page
        # allContentfulPageOrder {
        #   edges {
        #     node {
        #       pageTitle
        #     }
        #   }
        # }
      }
    `}
    render={(data) => (
      <Header pageTitles={transformQueryDataToObj(data)} {...props} />
    )}
  />
)
