const transformQueryDataToObj = data =>
  Object.keys(data).reduce(
    (p, c) => ({
      de: {
        ...p.de,
        [c.replace('allContentfulPage', '')]: data[c].edges[0].node
      },
      en: {
        ...p.en,
        [c.replace('allContentfulPage', '')]: data[c].edges[1].node
      }
    }),
    { de: {}, en: {} }
  )
exports.transformQueryDataToObj = transformQueryDataToObj

const snake = require('to-snake-case')

const components = {
  Home: 'index',
  Event: 'event',
  Specials: 'specials',
  Subscriptions: 'angebot',
  Info: 'info',
  Contact: 'kontakt',
  Order: 'bestellung',
  Agb: 'agb',
  PrivacyPolicy: 'privacy_policy'
}

const getHeaderPagesData = (pages, l) =>
  Object.keys(pages[l])
    .filter(key => pages[l][key].inHeader !== false)
    .map(key => ({
      name: pages[l][key].pageTitle,
      path: `${l === 'en' ? '/en/' : '/'}${
        key === 'Home' ? '' : snake(pages[l][key].pageTitle)
      }`,
      component: components[key]
    }))
exports.getHeaderPagesData = getHeaderPagesData
