import React from 'react'
import { ThemeProvider, injectGlobal } from 'styled-components'

import SEO from 'components/SEO'
import Header from 'components/Header'
import Main from 'components/Main'

import media from '../../utils/media'

import { theme } from 'utils'

injectGlobal`
  body { 
    font-family: Arial, Helvetica, sans-serif; 
  }
  
  ${media.lessThan('sm')`
    h2 { font-size: 12px; line-height: 16px; }
    p  { font-size: 16px; line-height: 22px; }
  `}
`

export default ({
  pageTitle,
  pageDescription,
  location,
  children,
  overlayOpened,
  toggleBackgroundBlur,
  locale
}) => (
  <React.Fragment>
    <SEO
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      pathname={location.pathname}
    />
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Header
          location={location}
          overlayOpened={overlayOpened}
          toggleBackgroundBlur={toggleBackgroundBlur}
          locale={locale || 'de'}
        />
        <Main>{children}</Main>
      </React.Fragment>
    </ThemeProvider>
  </React.Fragment>
)
